.camera-container{
  position: absolute;
  z-index: 2500;
  top:0px;
  left: 0px;
  width:100%;
  height: 100%;
}
.previewList img{
  max-width: 100%;
}
.react-html5-camera-photo{
  width: 100%;
  height: 100%;
}
.react-html5-camera-photo>video {
  width: 100%;
  height: 100%;
  
  object-fit: cover;
}

[type="file"] {
  height: 1px;
  overflow: hidden;
  width: 1px;
}
.camfile{
  background: #f15d22;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
	font-size: inherit;
  font-weight: 600;
  margin-bottom: 1rem;
  outline: none;
  padding: 1rem 50px;
  position: relative;
 
  vertical-align: middle;
}