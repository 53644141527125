.Home .lander {
   
    text-align: center;
  }
  
  .Home .lander h1 {
   
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }

  .Home .form-container .previewList{
    display: flex;
    flex-wrap: wrap;
  }
  .Home .form-container .previewList .previewList-item{
    width:25%;
    padding:3px;
    border:1px solid #000;
  }
  .Home .form-container .previewList .previewList-item img{
    max-width:100%;
    height:auto;
  }


  [type="file"] {
    height: 1px;
    overflow: hidden;
    width: 1px;
  }
  .camfile{
    background: #f15d22;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 1rem;
    outline: none;
    padding: 1rem 10px;
    position: relative;
   
    vertical-align: middle;
  }